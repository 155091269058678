import React, { useState } from 'react';
import { useAuth } from '../../../../contexts/auth'
import LogoImageUpload from './LogoImageUpload'
import { useAPI } from "../../../../contexts/api";
import _ from 'lodash';
import { CgSpinner } from "react-icons/cg";

function BusinessDetails({ setStep }) {

	const { user } = useAuth();
	const { updateBusiness, loading } = useAPI();

	const [businessDetails, setBusinessDetails] = useState(_.get(user, 'business.details', {}));

	const changeHandler = event => {
		event.persist();
		let value = event.target.value;
		setBusinessDetails(prevState => ({ ...prevState, [event.target.name]: value }));
	};

	const handleBusinessDetailsForm = async (e) => {
		e.preventDefault();
		const { businessId } = user;
		if (!businessDetails.phone) businessDetails.phone = user.phone;
		businessDetails.wizardStep = 3;
		console.log('businessDetails', businessDetails)
		const res = await updateBusiness({ businessId, details: businessDetails })
		setStep(3)
	}

	return (
		<>
			<div className="bg-white p-3 sm:p-6 shadow-sm border border-gray-200 rounded space-y-4 text-center">
				<div className='space-y-1 sm:max-w-sm mx-auto '>
					<h3 className='font-bold text-lg'>עתידות: הלקוחות שלכם יעופו על זה.</h3>
					<p>יש למלא את כל הפרטים! אנו נציג אותם ללקוחות בדף העסק שלכם. לאחר השלמת התהליך, תקבלו גישה למערכת הניהול.</p>
				</div>
				<div className="py-3 text-center mx-auto">
					<LogoImageUpload />
					<p className='text-xs font-medium text-blue-500'>מומלץ להוסיף לוגו או תמונה המתארת את העסק שלכם</p>
				</div>
				<hr />
				<form name='businessDetails' className='space-y-6' onSubmit={handleBusinessDetailsForm}>
					<div className="form-control space-y-1">
						<label className="label font-bold">
							<span className="label-text text-gray-800 md:text-base">שם העסק</span>
						</label>
						<input type="text" name='name' value={businessDetails.name || ''} onChange={changeHandler} required
							placeholder="לדוגמא: משה עיצוב שיער"
							className="border px-3 h-12" />
					</div>
					<div className="form-control space-y-1">
						<label className="label font-bold">
							<span className="label-text text-gray-800 md:text-base">סוג העסק</span>
						</label>
						<select name='type' onChange={changeHandler} value={businessDetails.type || ''} required className="select select-bordered  bg-white border border-gray-300 select-md w-full border px-3 h-12 bg-white font-normal">
							<option value="">בחר</option>
							<option value="מספרה">מספרה</option>
							<option value="מרפאה">מרפאה</option>
							<option value="מכון יופי">מכון יופי</option>
							<option value="חדר בריחה">חדר בריחה</option>
							<option value="מורים פרטיים">מורים פרטיים</option>
							<option value="מאמן כושר">מאמני כושר</option>
							<option value="יועצים">יועצים</option>
							<option value="אחר...">אחר...</option>
						</select>
					</div>
					<div className="form-control space-y-2">
						<label className="label font-bold mb-0 pb-0">
							<span className="label-text md:text-base text-gray-800">כמה מילים על העסק שלך</span>
						</label>
						<div className="text-right pr-1 text-xs text-gray-500 pb-2">
							<div className=''>זה המקום לכתוב תיאור קצר על העסק שלכם, ולמה כדאי ללקוחות להגיע. לדוגמא: ״מומחה לעיצוב שיער לגברים ונשים, שירותיות, מקצועיות, מחירים נוחים ויחס חם לכולם״</div>
							<strong className='text-gray-800'>כאן לא כותבים שירותים ומחירים :)</strong>
						</div>
						<textarea name='description' onChange={changeHandler} value={businessDetails.description || ''} required placeholder="לדוגמא: מומחים לעיצוב שיער, החלקות, תספורות וצבע. מבטיחים מקצועיות ויחס אישי וחם לכל לקוח ולקוחה."
							className="border text-sm bg-white h-20 leading-relaxed p-3" />
					</div>
					<div className="form-control space-y-1">
						<label className="label font-bold mb-0 pb-0">
							<span className="label-text md:text-base text-gray-800">כתובת מלאה של העסק</span>
						</label>
						<p className="text-right pr-1 text-xs text-gray-500 pb-2">יש להזין כתובת מלאה הכוללת רחוב, מס׳ ועיר. לדוגמא ״מנחם בגין 120, תל אביב״</p>
						<input name='address' onChange={changeHandler} value={businessDetails.address || ''} required type="text" placeholder="לדוגמא: מנחם בגין 22, תל אביב"
							className="border px-3 h-12 bg-white" />
					</div>
					<div className="form-control space-y-1 hidden">
						<label className="label font-bold">
							<span className="label-text md:text-base text-gray-800">מס׳ הטלפון שלכם</span>
						</label>
						<input required type="text" pattern="\d*" name='phone' onChange={changeHandler} value={businessDetails.phone || user.phone} placeholder="הזן את מס׳ הטלפון של העסק"
							className="border px-3 h-12 bg-white" />
					</div>

					<div className="form-control pt-3">
						<button type='submit' disabled={loading}
							className='flex justify-center items-center disabled:opacity-50 text-center text-md h-12 w-full bg-green-500 text-white font-bold py-3 px-4  hover:border-green-500 rounded'>
							{loading ? <CgSpinner className='mx-auto animate-spin' size={40} /> : <span>המשך להגדרת זמנים</span>}
						</button>
					</div>
				</form>
			</div>
		</>
	);
}

export default BusinessDetails;

