import React from 'react'
import SettingsSelect from './SettingsSelect'

function SettingsItem({ title, children, onChange, value, options, isDisabled }) {
    return (
        <div className={`border text-sm rounded-sm p-3 ${isDisabled ? 'opacity-50' : ''}`}>
            <div
                className='md:space-x-5 md:space-x-reverse flex md:justify-between items-start md:items-center flex-col md:flex-row space-y-3 md:space-y-0'>
                <div className='md:w-1/2 space-y-1'>
                    <label className="font-bold">
                        <div className="label-text md:text-base text-gray-800">{title}</div>
                    </label>
                    <p className='text-sm md:text-sm text-gray-700'>
                        {children}
                    </p>
                </div>
                <SettingsSelect onChange={onChange} value={value} options={options} isDisabled={isDisabled} />
            </div>
        </div>
    )
}

export default SettingsItem