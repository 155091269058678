import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../contexts/auth'
import AvailabilitySettings from './AvailabilitySettings';
import AvailabilityLimits from './AvailabilityLimits';
import { CgSpinner } from "react-icons/cg";
import { useAPI } from "../../../../contexts/api";
import { getTimeSettings } from '../../../../services/frontendServices/AppointmentService'

function BusinessSettings({ setStep }) {
	const { user } = useAuth();
	const { updateBusiness, loading } = useAPI();
	const [timeSettings, setTimeSettings] = useState(getTimeSettings(user))
	const details = _.get(user, 'business.details', {});

	const saveBusinessTimeSettings = async (e) => {
		e.preventDefault();
		const { businessId } = user;
		details.wizardStep = 4;
		if (!timeSettings.availability || timeSettings.availability.length === 0) {
			timeSettings.availability = [
				{ day: 1, active: true, startTime: '08:00', endTime: '17:00' },
				{ day: 2, active: true, startTime: '08:00', endTime: '17:00' },
				{ day: 3, active: true, startTime: '08:00', endTime: '17:00' },
				{ day: 4, active: true, startTime: '08:00', endTime: '17:00' },
				{ day: 5, active: true, startTime: '08:00', endTime: '17:00' },
				{ day: 6, active: true, startTime: '08:00', endTime: '13:00' },
				{ day: 7, active: false, startTime: '08:00', endTime: '17:00' },
			]
		}

		const res = await updateBusiness({ businessId, details, timeSettings })
		setTimeSettings(timeSettings)
		setStep(4)
	}

	return (
		<>
			<div className="bg-white p-3 sm:p-6 shadow-sm border border-gray-200 rounded space-y-4 text-center">
				<form name='businessDetails' className='space-y-4' onSubmit={saveBusinessTimeSettings}>
					<div className="form-control text-right space-y-3">
						<div>
							<label className="font-bold">
								<div className="label-text md:text-base text-gray-800">ימי ושעות פעילות העסק</div>
							</label>
							<p className='text-sm md:text-sm'>כאן תוכלו להגדיר באילו ימים ושעות העסק שלכם יהיה פתוח,
								ומתי לקוחות
								יוכלו לקבוע תור.</p>
						</div>

						<AvailabilitySettings timeSettings={timeSettings} setTimeSettings={setTimeSettings} />
					</div>

					<p>ממש לאחר סיום תהליך ההגדרה הראשוני, תוכלו לשנות דברים הנוגעים להגדרות זמנים, קביעת תורים, ביטולים וכו׳ במסך ההגדרות.</p>
					<div className="form-control text-right space-y-3 hidden">
						<AvailabilityLimits timeSettings={timeSettings} setTimeSettings={setTimeSettings} />
					</div>

					<div className="form-control pt-3 flex flex-row align-center justify-between">
						<button onClick={() => setStep(2)}
							className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-3 px-4 hover:border-gray-500 rounded w-full ml-1">
							לשלב הקודם
						</button>
						<button type='submit' disabled={loading}
							className='flex justify-center items-center disabled:opacity-50 whitespace-nowrap text-center text-md h-12 w-full bg-green-500 text-white font-bold py-3 px-4 hover:border-green-500 rounded'>
							{loading ? <CgSpinner className='mx-auto animate-spin' size={40} /> : <span>הבא: שירותי העסק</span>}
						</button>
					</div>
				</form>
			</div>
		</>
	);
}

export default BusinessSettings;