// contexts/auth.js

import React, { createContext, useState, useContext, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import axios from 'axios';
import useUser from '../lib/useUser'
import { toast } from 'react-toastify';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const { pathname } = useRouter();

	let redirectTo = '/app/login';
	if (pathname === '/app/signup') {
		redirectTo = null;
	}
	const { user, mutateUser } = useUser({ redirectTo })
	const [userSession, setUserSession] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (user && user.isLoggedIn) {
			setUserSession(user);
		}
	}, [])

	const doLogin = async (phone) => {
		try {
			const res = await axios.post(`/api/auth/login/`, { phone });
			return res;
		} catch (ex) {
			console.log('failed to login', ex)
		}
	}

	const doSignup = async ({ phone, firstName, lastName, email }) => {
		setLoading(true);
		try {
			const res = await axios.post(`/api/auth/signup/`, { phone, firstName, lastName, email })
			if (res.data) {
				if (res.data.sentCode) {
					setLoading(false);
					return true
				} else if (res.data.exists) {
					toast.error('מס׳ הטלפון שהוזן כבר משוייך לחשבון עסקי אחר.');
				}
			}
			setLoading(false);
		} catch (ex) {
			console.log('signup failed', ex)
			toast.error('אופס....בדקו שכל הפרטים שהזנתם תקינים ונסו שנית.');
			setLoading(false);
		}
	}

	const doSMSVerification = async (phone, smsCode) => {
		setLoading(true);
		try {
			const res = await axios.post(`/api/auth/verifySMS/`, { phone, smsCode });
			if (res.data && res.data._id) {
				const userData = res.data;
				setUserSession(userData);
				await mutateUser(userData)
			}
		} catch (ex) {
			console.log('login failed', ex)
			toast.error('הקוד שהוזן אינו תואם לקוד ששלחנו לך.');
			setLoading(false);
		}
	}


	const doLogout = async () => {
		try {
			const res = await axios.post(`/api/auth/logout/`, {});
			if (res.data && res.data.isLoggedIn === false) {
				console.log('logged out!')
				Router.replace('/app/login');
				setUserSession({});
				await mutateUser({})
			}
		} catch (ex) {
			console.log('logout failed', ex)
		}
	}

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: !!user || !!userSession,
				user,
				userSession,
				loading,
				doLogin,
				doSignup,
				doSMSVerification,
				doLogout
			}}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => useContext(AuthContext)
