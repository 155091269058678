module.exports = {
  free: {
    name: 'Free (החינמית)',
    plan: 'free',
    hebrew: 'החינמית',
    title: '',
    price: 0,
    appointments: 10,
    customers: 10,
    smsCredits: 50
  },
  small: {
    name: 'Small (הקטנה)',
    plan: 'small',
    hebrew: 'הקטנה',
    title: 'לעסקים צומחים 🌱',
    price: 59,
    appointments: 25,
    customers: 200,
    smsCredits: 100,
    extraSMS: 0.08
  },
  basic: {
    name: 'Basic (הבסיסית)',
    plan: 'basic',
    hebrew: 'הבסיסית',
    title: 'לעסקים שממריאים 📈',
    price: 99,
    appointments: 50,
    customers: 5000,
    smsCredits: 200,
    extraSMS: 0.08
  },
  advanced: {
    name: 'Advanced (המתקדמת)',
    plan: 'advanced',
    hebrew: 'המתקדמת',
    title: 'לעסקים מתקדמים 💪',
    price: 149,
    appointments: 100,
    customers: 5000,
    smsCredits: 300,
    extraSMS: 0.08
  },
  advancedOnSale: {
    name: 'Advanced (המתקדמת)',
    plan: 'advancedOnSale',
    hebrew: 'המתקדמת',
    title: 'לעסקים מתקדמים 💪',
    price: 89,
    appointments: 100,
    customers: 5000,
    smsCredits: 300,
    extraSMS: 0.08
  },
  unlimited: {
    name: 'Unlimited (ללא הגבלה)',
    plan: 'unlimited',
    hebrew: 'ללא הגבלה',
    title: 'הבחירה של הגדולים 🚀',
    price: 199,
    appointments: 5000,
    customers: 5000,
    smsCredits: 2000,
    extraSMS: 0.08
  }
}