import React, { useEffect } from 'react';
import { FaRegCalendarAlt, FaUmbrellaBeach } from "react-icons/fa";
import { RiGroupLine, RiStarSmileLine, RiAccountCircleLine, RiInstagramLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { BiIdCard } from "react-icons/bi";
import { GoListUnordered } from "react-icons/go";
import { HiOutlineBriefcase, HiOutlineChartPie, HiChartBar, HiOutlineSupport } from "react-icons/hi";
import ActiveLink from "./ActiveLink";
import { useRouter } from "next/router";
import Link from "next/link";
import { BsCreditCard } from "react-icons/bs"

function MainSidebar({ setRouteTitle, user }) {
  const clickCallback = (title, icon) => {
    setRouteTitle({ title, icon });
  }

  return (
    <ul className="mt-1 md:mt-2 p-1 lg:p-0 text-gray-700">
      <SidebarItem href='/app/' icon={<HiOutlineBriefcase size={22} />} text={'העסק שלי'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/account/' icon={<RiAccountCircleLine size={22} />} text={'החשבון שלי'}
        clickCallback={clickCallback} />
      {
        (!user?.business?.creditBoxToken || !user?.business?.invoiceToken) &&
        <li className='p-2 md:px-4 md:py-2.5 cursor-pointer hover:opacity-90 rounded bg-indigo-600 transition'>
          <Link href='https://land.zmantov.co.il/invoice-offer/'>
            <a target="_blank" className='tracking-tight flex items-center text-white'>
              <BsCreditCard size={22} />
              <span className='mr-4 flex items-center'>חשבוניות וסליקה
                <span
                  className="bg-white text-indigo-600 tracking-normal text-xs font-semibold mr-2 px-1.5 py-0.5 rounded">חדש</span>
              </span></a>
          </Link>
        </li>}
      <SidebarItem href='/app/calendar/' icon={<FaRegCalendarAlt size={22} />} text={'יומן עבודה'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/availability/' icon={<FaUmbrellaBeach size={22} />} text={'חופשים והפסקות'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/staff/' icon={<RiGroupLine size={21} />} text={'אנשי צוות'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/services/' icon={<GoListUnordered size={22} />} text={'שירותים'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/customers/' icon={<BiIdCard size={22} />} text={'לקוחות'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/marketing/' icon={<HiChartBar size={22} />} text={'שיווק'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/stats/' icon={<HiOutlineChartPie size={22} />} text={'סטטיסטיקה ודוחות'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/settings/' icon={<FiSettings size={22} />} text={'הגדרות'}
        clickCallback={clickCallback} />
      <SidebarItem href='/app/updates/' icon={<RiStarSmileLine className='-ml-1' size={24} />} text={'מה חדש?'}
        clickCallback={clickCallback} />
      <li className='p-2 md:px-4 md:py-2.5 cursor-pointer hover:bg-gray-100 rounded text-gray-900'>
        <Link href='https://zmantov.tawk.help/'>
          <a target="_blank" className='tracking-tight flex items-center'>
            <HiOutlineSupport size={22} />
            <span className='mr-4 flex items-center'>שאלות ומדריכים
            </span></a>
        </Link>
      </li>
      <li className='p-2 md:px-4 md:py-2.5 cursor-pointer hover:bg-gray-100 rounded text-gray-900'>
        <Link href='https://instagram.com/zmantov'>
          <a target="_blank" className=' tracking-tight flex items-center'>
            <RiInstagramLine size={22} />
            <span className='mr-4'>עקבו אחרינו zmantov@</span></a>
        </Link>
      </li>

      {/*<div className='flex justify-around '>*/}
      {/*<div className='text-xs text-center tracking-tight justify-center text-gray-600 items-center border rounded flex mt-2 px-2 pl-3'>*/}
      {/*  <img className='w-8 h-8 ml-2 p-1' src='/img/irs-badge.svg'/>*/}
      {/*  <div className='text-gray-600 flex flex-col items-start'>*/}
      {/*    <span>תוכנה מאושרת (מס׳ 243201)</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*</div>*/}
    </ul>
  );
}

const SidebarItem = ({ href, icon, text, clickCallback, onClick }) => {
  const { asPath } = useRouter()

  useEffect(() => {
    if (asPath === href) {
      clickCallback(text, icon);
    }
    if (asPath.indexOf('account/upgrade') > -1) {
      clickCallback('מחירים וחבילות', <HiOutlineBriefcase size={23} />)
    }
  }, [asPath, href, text])

  return (
    <li className='cursor-pointer hover:bg-gray-100 rounded text-gray-900' onClick={() => {
      onClick ? onClick() : null;
    }}>
      <ActiveLink activeClassName='active' href={href || ''}>
        <a href={href ? href : null}
          className='flex items-center p-2 md:px-4 md:py-2.5 rounded-md lg:rounded-none focus:shadow-outline'>
          <span className='ml-4'>{icon}</span>
          <span className='text-base'>{text}</span>
        </a>
      </ActiveLink>
    </li>
  );
}

export default MainSidebar;


