import React from 'react';
import { CgSpinner } from "react-icons/cg";

function LoaderIcon({ children, spin, size }) {
	return (
		<span className="flex justify-center items-center">
			{spin ? <CgSpinner className='animate-spin' size={size || 20} /> : <span>{children}</span>}
		</span>
	);
}

export default LoaderIcon;
