import React, { useState } from 'react'
import { FcHighPriority } from 'react-icons/fc'
import Link from 'next/link'
import { daysBetween, dateByAddingDays } from '../../../../utils/dateUtils'
import useUser from '../../../../lib/useUser'
import plans from '../../../../plans'
import { useAPI } from '../../../../contexts/api'

function AccountBanner({ isTrial, willReachLimit, hasDebt }) {
    const { user } = useUser()
    const { getDebtDetails } = useAPI()
    const [debtTotal, setDebtTotal] = useState(null)

    let message
    let href = '/app/account/'
    let linkText
    if (isTrial) {
        href += 'upgrade'
        linkText = 'רכישת מנוי'
        let endOfTrialDate = dateByAddingDays(user.business.onboardingComplete, 30)
        const daysOfTrialLeft = daysBetween(endOfTrialDate, new Date())
        if (daysOfTrialLeft === 1) message = `נותר יום לסיום הניסיון, רכשו מנוי על מנת להמשיך להנות מלקוחות שלא מתקשרים לקבוע תורים :)`
        else message = `נותרו עוד ${daysOfTrialLeft} ימים לסיום הניסיון, רכשו מנוי על מנת להמשיך להנות מלקוחות שלא מתקשרים לקבוע תורים :)`
    } else if (willReachLimit) {
        message = 'אתם בדרך לניצול מקסימלי של התוכנית החודשית שלכם.'
        linkText = 'החשבון שלי'
    } else if (hasDebt) {
        updateDebtTotal()
        href += 'upgrade/#upgrade-options'
        linkText = 'לבחירת מנוי'
        message = (`בחשבונך קיים חוב של ${debtTotal} ש"ח. על מנת לחזור לעבודה רציפה יש להסדיר את התשלום.`)
    }

    async function updateDebtTotal() {
        const debtDetails = await getDebtDetails()
        const total = debtDetails?.data?.upcomingCharges.total || plans[user.business.subscription.plan].price
        setDebtTotal(total)
    }

    if (!isTrial && !willReachLimit && (!hasDebt || !debtTotal)) return <div></div>

    return (
        <div className={`flex justify-between items-center bg-red-50 border-r-4 border-red-500 text-red-600 p-3 py-2 -mt-2 mb-3 md:-mt-3 md:mb-5 border-r-4 rounded ${(willReachLimit || hasDebt) && 'bg-red-50 border-red-500 text-red-600'} ${isTrial && 'bg-blue-50 border-blue-500 text-blue-600'}`}
            role="alert">
            <div className='tracking-tight md:text-base w-full'>
                <strong className="animate-pulse font-bold pl-1 flex items-center">
                    <FcHighPriority size={18} />
                    <span className='pr-1'>שימו לב</span>
                </strong>
                <span className="block sm:inline text-sm md:text-base tracking-tight">
                    {message}
                </span>
            </div>
            <div className='flex w-36 justify-end'>
                <Link href={href} scroll={hasDebt ? false : true}>
                    <a
                        className={`hover:opacity-90 shadow border text-white text-sm md:text-base font-medium p-1 px-1.5 py-1.5 rounded ${(willReachLimit || hasDebt) && 'bg-red-500 border-red-500'} ${isTrial && 'bg-blue-500 border-blue-500'}`}>
                        {linkText}
                    </a>
                </Link>
            </div>
        </div >
    )
}

export default AccountBanner