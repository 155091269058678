import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../contexts/auth'
import { useAPI } from "../../../../contexts/api";
import { FcApproval } from "react-icons/fc";
import { IoCopyOutline } from "react-icons/io5";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRouter } from 'next/router'
import { CgSpinner } from "react-icons/cg";
import Emoji from "a11y-react-emoji";
import useUser from "../../../../lib/useUser";


function WizardComplete({ setStep }) {
	const router = useRouter()
	const { user, mutateUser } = useUser();
	const [copied, setCopied] = useState(false);
	const [finishingWizard, setFinishingWizard] = useState(false);
	const { setWizardComplete } = useAPI();

	useEffect(async () => {
		const { businessId } = user;
		await setWizardComplete({ businessId })
		await mutateUser()
	}, []);



	const handleWizardComplete = async () => {
		setFinishingWizard(true);
		const { businessId } = user;
		const { data } = await setWizardComplete({ businessId })
		if (data.success) {
			setFinishingWizard(false);
			setStep(6)
			await mutateUser()
			await router.push('/app/')
		}
	}

	return (
		<>
			<div className="bg-white p-3 sm:p-6 shadow-sm border border-gray-200 rounded space-y-6 text-center">
				<div className='space-y-2 sm:max-w-lg mx-auto text-center flex flex-col justify-center items-center'>
					<div className='text-5xl'><Emoji symbol="🎉" label="confetti" /></div>
					<h3 className='font-bold text-lg pt-2'>מדהים!</h3>
					<p>הוספתם את העסק שלכם בהצלחה.</p>
					<p className='text-green-600 font-semibold'>לאחר לחיצה על כפתור הירוק, תגיעו למערכת הניהול דרכה תוכלו לראות את יומן העבודה שלכם, לשנות הגדרות, ולהתחיל לקבל לקוחות.</p>
					<p className='text-gray-600'>אנחנו זמינים לכל שאלה בעמוד האינסטגרם שלנו ובצ׳אט בפינה הימנית למטה :)</p>
				</div>

				<div className='space-y-4'>
					<div className="form-control pt-3 flex flex-row align-center justify-between">
						<button disabled={finishingWizard} onClick={() => setStep(4)}
							className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-3 px-4  hover:border-gray-500 rounded w-full ml-1">
							לשלב הקודם
						</button>
						<button disabled={finishingWizard} onClick={() => {
							handleWizardComplete()
						}}
							className='flex justify-center items-center disabled:opacity-50 text-center text-md h-12 w-full bg-green-500 text-white font-bold py-3 px-4  hover:border-green-500 rounded'>
							{finishingWizard ? <CgSpinner className='mx-auto animate-spin' size={40} /> :
								<span>למערכת הניהול</span>}
						</button>
					</div>
				</div>

			</div>
		</>
	);
}

export default WizardComplete;


