import React, { useState } from 'react';
import { FaRegBell } from 'react-icons/fa'
import EventsFeed from "./EventsFeed";
import useScrollBlock from "../../../lib/useScrollBlock";

function NotificationsCenter() {
	const [showNotificationsCenter, setShowNotificationsCenter] = useState(false);
	const [blockScroll, allowScroll] = useScrollBlock();

	const toggleNotificationsCenter = e => {
		e.preventDefault();
		!showNotificationsCenter ? blockScroll() : allowScroll();
		setShowNotificationsCenter(!showNotificationsCenter);
	}

	return (
		<div className="relative">
			<button
				className="z-50 block text-2xl flex items-center rounded-md bg-white hover:bg-gray-100 focus:bg-gray-100 p-2 lg:p-3 text-gray-600 focus:outline-none"
				onClick={toggleNotificationsCenter}>
				<FaRegBell />
			</button>

			{showNotificationsCenter &&
				<div className="fixed inset-0 h-full w-full z-10" onClick={toggleNotificationsCenter} />}

			{showNotificationsCenter &&
				<div
					className="fixed top-0 sm:top-auto sm:absolute w-full h-full sm:h-auto sm:w-96 left-0 sm:mt-1 bg-white sm:rounded-md border shadow-lg overflow-hidden z-20">
					<EventsFeed toggle={toggleNotificationsCenter} />
				</div>
			}
		</div>
	);
}

export default NotificationsCenter;
