import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus } from "react-icons/fa";

function NumberStepper({ value, changeHandler, steps }) {

	let [internalValue, setInternalValue] = useState(value)

	useEffect(() => {
		setInternalValue(value);
	}, [value])

	const handlePlusButton = (e) => {
		e.preventDefault();
		const newValue = parseInt(internalValue += (steps ? steps : 1))
		setInternalValue(newValue);
		changeHandler(newValue)
	}

	const handleMinusButton = (e) => {
		e.preventDefault();
		const newValue = parseInt(internalValue -= (steps ? steps : 1))
		setInternalValue(newValue);
		changeHandler(newValue)
	}
	const handleChange = (e) => {
		if (e.target.value == '' || !e.target.value) {
			setInternalValue(value)
			changeHandler(value)
		} else if (!isNaN(e.target.value)) {
			const newValue = parseInt(e.target.value)
			setInternalValue(newValue)
			changeHandler(newValue)
		}
	}


	return (
		<>
			<div className="flex">
				<button className="btn btn-square btn-md rounded-l-none" onClick={handlePlusButton}>
					<FaPlus />
				</button>
				<input type="text" pattern="\d*" onChange={handleChange} value={internalValue || 25}
					className="border-t direction-ltr border-b border-gray-300 w-full rounded-none text-center text-base" />
				<button className="btn btn-square btn-md rounded-r-none" onClick={handleMinusButton}>
					<FaMinus />
				</button>
			</div>
		</>
	)
}

export default NumberStepper;


