import React, { useEffect, useRef } from 'react';
import { useAPI } from "../../../contexts/api";
import FeedItem from "./FeedItem";
import useSWRInfinite from 'swr/infinite'
import useOnScreen from "../../../lib/useOnScreen";
import FeedItemLoadingSkeleton from "./FeedItemLoadingSkeleton";
import { GrClose } from "react-icons/gr";
const PAGE_SIZE = 5

const getKey = (pageIndex, previousPageData, queryKey, pageSize) => {
	if (previousPageData && !previousPageData.length) return null // reached the end
	return `${queryKey}?limit=${pageSize}&page=${pageIndex + 1}`
}

function EventsFeed({ toggle }) {
	const { getEventsFeed } = useAPI();
	const ref = useRef()
	const isVisible = useOnScreen(ref)

	const { data, error, size, setSize, isValidating } = useSWRInfinite(
		(...args) => getKey(...args, '/events/', PAGE_SIZE),
		getEventsFeed
	)

	const events = data ? [].concat(...data) : []
	const isLoadingInitialData = !data && !error
	const isLoadingMore =
		isLoadingInitialData ||
		(size > 0 && data && typeof data[size - 1] === 'undefined')
	const isEmpty = data?.[0]?.length === 0
	const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE)
	const isRefreshing = isValidating && data && data.length === size

	useEffect(() => {
		if (isVisible && !isReachingEnd && !isRefreshing) {
			setSize(size + 1)
		}
	}, [isVisible, isRefreshing])

	return (
		<>
			<>
				<div className="bg-white mx-auto w-full h-full divide-y sm:rounded-md overflow-y-auto sm:max-h-64 pt-14 sm:pt-0">
					<div
						className='font-medium bg-gray-100 fixed sm:sticky w-full top-0 sm:-mt-1 p-1 pr-4 z-40 border-b border-gray-200 flex items-center justify-between'>
						<div className='text-lg sm:text-base'>עדכונים והתראות</div>
						<button className='font-extrabold text-gray-700 sm:text-xl hover:bg-gray-200 p-3 sm:p-2 rounded text-2xl'
							onClick={toggle}>
							<GrClose />
						</button>
					</div>

					{events && events.length > 0 && events.map((ev) => (
						<FeedItem key={ev._id} event={ev} />
					))}

					<div ref={ref}>
						{isEmpty && <div className='text-center font-medium text-gray-400 p-3'>אין אירועים להצגה</div>}
						{isLoadingMore && !isEmpty && <FeedItemLoadingSkeleton />}
						{isReachingEnd && !isEmpty &&
							<div className='text-center font-medium text-gray-400 p-4 py-6'>אין עוד אירועים להצגה</div>}
					</div>
				</div>
			</>
		</>
	);
}

export default EventsFeed;
