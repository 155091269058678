import React, { useState, useEffect } from 'react';
import { RiDeleteBin6Line, RiEditLine, RiAddFill } from "react-icons/ri";
import AddServiceModal from "../modals/AddServiceModal";
import { useAPI } from "../../../../contexts/api";
import { useAuth } from "../../../../contexts/auth";
import { CgSpinner } from "react-icons/cg";
import utils from "../../../../utils";
import useScrollBlock from "../../../../lib/useScrollBlock";

function ServicesList({ setTheServices }) {
	const [loadingServices, setLoadingServices] = useState(true);
	const { user } = useAuth();
	const { businessId } = user;
	const [showModal, setShowModal] = useState(false);
	const [services, setServices] = useState([]);
	const { getServices, deleteService } = useAPI();
	const [serviceToDelete, setServiceToDelete] = useState('');
	const [serviceToEdit, setServiceToEdit] = useState({});
	const [blockScroll, allowScroll] = useScrollBlock();

	useEffect(() => {
		if (showModal) {
			blockScroll();
		} else {
			allowScroll();
		}
	}, [showModal])

	useEffect(async () => {
		await newServiceModalCallback();
	}, [])

	const newServiceModalCallback = async () => {
		setLoadingServices(true)
		const fetchedServices = await getServices({ businessId });
		if (fetchedServices && fetchedServices.data.services) {
			setServices(fetchedServices.data.services);
			setTheServices(fetchedServices.data.services)
		}
		setLoadingServices(false)
		setShowModal(false);
	}

	const handleDeleteService = (serviceId) => {
		const newList = services.filter((item) => item._id !== serviceId);
		deleteService({ businessId, serviceId });
		setServices(newList);
		setTheServices(newList)
	}

	const handleEditService = (service) => {
		setServiceToEdit(service);
		setShowModal(true);
	}

	return (
		<>
			{!loadingServices ?
				<>

					{services && services.length > 0 ?
						<div className='space-y-2'>
							<div className='border'>
								<div className="grid grid-cols-4 bg-gray-100 mb-1 font-medium">
									<div className="h-8 flex items-center col-span-2 pr-2">שם השירות</div>
									<div className="h-8 flex items-center  col-span-1">עלות</div>
									<div className="h-8 flex items-center justify-center col-span-1"></div>
								</div>

								<div className='space-y-6 text-sm p-2 pb-4'>

									{services.map((service) => (
										<div key={service._id} className='grid grid-cols-4 border- items-center flex'>
											<div className="h-8 flex col-span-2 flex-col text-sm text-right">
												<div className='text-gray-700 font-semibold'>{service.name}</div>
												<p className='text-xs text-gray-500'>משך: {utils.timeConvert(service.length)}</p>
											</div>
											<div className="h-8 flex col-span-1 items-center text-right text-sm">
												{service.startingPrice && 'החל מ-'}
												₪{service.price}
											</div>
											<div className="h-8 flex col-span-1 items-center justify-end text-sm">
												<label onClick={() => handleDeleteService(service._id)}
													className="cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-2 border border-gray-400 rounded ml-1">
													<RiDeleteBin6Line size={16} />
												</label>
												<button onClick={() => handleEditService(service)}
													className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-2 border border-gray-400 rounded">
													<RiEditLine size={16} />
												</button>
											</div>
										</div>
									))
									}

								</div>
							</div>
							<div>
								<button onClick={() => {
									setServiceToEdit({});
									setShowModal(true)
								}}
									className="flex cursor-pointer w-full sm:w-44 items-center justify-center bg-blue-500 hover:bg-blue-400 text-white font-bold py-3 px-3 pl-4 hover:border-blue-500 rounded">
									<RiAddFill size={22} />
									<span className='pr-1'>הוספת שירות</span>
								</button>
							</div>
						</div>
						:
						<div className='p-5 border-dashed border-2'>
							<button onClick={() => {
								setServiceToEdit({});
								setShowModal(true)
							}}
								className="flex cursor-pointer mx-auto w-full sm:w-44 items-center justify-center bg-blue-500 hover:bg-blue-400 text-white font-bold py-3 px-3 pl-4 hover:border-blue-500 rounded">
								<RiAddFill size={22} />
								<span className='pr-1'>הוספת שירות</span>
							</button>
						</div>
					}
				</>
				: <CgSpinner className='mx-auto animate-spin' size={50} />}
			<AddServiceModal callback={newServiceModalCallback} showModal={showModal} setShowModal={setShowModal}
				serviceToEdit={serviceToEdit} />

		</>
	)
}

export default ServicesList;

