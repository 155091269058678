import React, { useState, useEffect } from 'react';
import TimeSelect from './TimeSelect'
import utils from '../../../../utils'

function AvailabilitySettings({ timeSettings, setTimeSettings }) {
	const days = [
		{ day: 1, active: true, startTime: '08:00', endTime: '17:00' },
		{ day: 2, active: true, startTime: '08:00', endTime: '17:00' },
		{ day: 3, active: true, startTime: '08:00', endTime: '17:00' },
		{ day: 4, active: true, startTime: '08:00', endTime: '17:00' },
		{ day: 5, active: true, startTime: '08:00', endTime: '17:00' },
		{ day: 6, active: true, startTime: '08:00', endTime: '13:00' },
		{ day: 7, active: false, startTime: '08:00', endTime: '17:00' },
	]

	const [timeOptions, setTimeOptions] = useState(timeSettings.availability.length > 0 ? timeSettings.availability : days);

	useEffect(() => {
		setNewList(timeSettings.availability.length > 0 ? timeSettings.availability : days)
	}, [])

	function setNewList(newList) {
		setTimeSettings(prev => ({
			...prev,
			availability: newList
		}))
	}

	const onTimeSelect = (option) => {
		const newList = timeOptions.map((item) => {
			if (item.day === option.day) {
				return option;
			}
			return item;
		});
		setTimeOptions(newList);
		setNewList(newList);
	}

	const toggleHandler = (day) => {
		const newList = timeOptions.map((item) => {
			if (item.day === day) {
				return {
					...item,
					active: !item.active,
				};
			}
			return item;
		});
		setTimeOptions(newList);
		setNewList(newList);
	}

	return (
		<>
			<div className='border text-sm rounded-sm'>
				<div className="grid grid-cols-4 bg-gray-100 mb-1  font-medium">
					<div className="h-8 flex items-center justify-center">יום</div>
					<div className="h-8 flex items-center justify-center">פתוח?</div>
					<div className="h-8 flex items-center justify-center">פתיחה</div>
					<div className="h-8 flex items-center justify-center">סגירה</div>
				</div>

				<div className='space-y-2 text-sm'>
					{timeOptions.map((option) => (
						<div className={`grid grid-cols-4 ${!option.active && 'bg-gray-200 opacity-50'}`}
							key={option.day}>
							<div
								className="h-8 flex items-center justify-center text-right text-sm">{utils.getDayName(option.day)}</div>
							<div className="h-8 flex items-center justify-center ">
								<div className='direction-ltr form-control'>
									<label className='cursor-pointer'>
										<input type="checkbox" checked={option.active}
											onChange={() => toggleHandler(option.day)}
											className="toggle toggle-accent " />
										<span className="toggle-mark bg-gray-400" />
									</label>
								</div>
							</div>
							<div className="h-8 flex items-center justify-center text-sm text-center">
								{!option.active ?
									'סגור'
									:
									<TimeSelect defaultValue={option.startTime} onTimeSelect={onTimeSelect}
										option={option} startTime={true} />
								}
							</div>
							<div className="h-8 flex items-center justify-center text-sm text-center">
								{!option.active ?
									'סגור'
									:
									<TimeSelect defaultValue={option.endTime} onTimeSelect={onTimeSelect}
										option={option} />
								}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	)
}

export default AvailabilitySettings;

