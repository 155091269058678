import React from 'react'

function SettingsSelect({ onChange, value, options, isDisabled }) {
    return (
        <div className='w-full md:w-1/2'>
            <select onChange={onChange} value={value} disabled={isDisabled}
                className="select select-bordered bg-white border border-gray-300 select-md w-full border px-3 h-12 font-medium bg-white w-full">
                {
                    options.map(option => (
                        <option className='text-center' key={option.text}
                            value={option.value}>{option.text}</option>
                    ))
                }
            </select>
        </div>
    )
}

export default SettingsSelect