import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../contexts/auth'
import { CgSpinner } from "react-icons/cg";
import { useAPI } from "../../../../contexts/api";
import ServicesList from './ServicesList'

function BusinessServices({ setStep }) {
	const { user } = useAuth();
	const { updateBusiness, loading } = useAPI();
	const [services, setServices] = useState([]);
	return (
		<>
			<div className="bg-white p-3 sm:p-6 shadow-sm border border-gray-200 rounded space-y-6 text-center">
				<div className='space-y-1 sm:max-w-xl mx-auto tracking-tight text-sm'>
					<h3 className='font-bold text-lg'>אילו שירותים אתם מציעים?</h3>
					<div className='bg-red-500 inline-block px-3 py-px text-white rounded '>חשוב מאוד</div>
					<p className='pt-3'>הוסיפו את שירותי העסק שלכם.</p>
					<p className='pt-2'>יש להוסיף כל שירות בנפרד, לדוגמא: <strong className=''>תספורת</strong>, <strong className=''>תספורת + זקן</strong>, <strong
						className=''>החלקה</strong>. ללא הוספת שירותים, לקוחות <span className='font-bold underline text-red-600'>לא יוכלו</span> לקבוע תור.</p>
					<p className='pt-2'>תוכלו לערוך את השירותים בכל זמן לאחר סיום התהליך.</p>
				</div>

				<div>
					<ServicesList setTheServices={setServices} />
				</div>

				<div className='space-y-4'>
					<div className="form-control pt-3 flex flex-row align-center justify-between">
						<button onClick={() => setStep(3)}
							className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-3 px-4  hover:border-gray-500 rounded w-full ml-1">
							לשלב הקודם
						</button>
						<button disabled={services.length === 0 || loading} onClick={() => { setStep(5) }}
							className='flex justify-center items-center disabled:opacity-50 text-center text-md h-12 w-full bg-green-500 text-white font-bold py-3 px-4  hover:border-green-500 rounded'>
							<span>הבא</span>
						</button>
					</div>
				</div>

			</div>
		</>
	);
}

export default BusinessServices;

