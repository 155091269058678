import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../contexts/auth'
import BusinessDetails from './BusinessDetails';
import BusinessSettings from './BusinessSettings';
import BusinessServices from './BusinessServices';
import WizardComplete from './WizardComplete';
import AccountBanner from './AccountBanner'
import _ from 'lodash'
import moment from "moment-timezone"

function BusinessWizard({ children, wizardStep }) {
	const { user } = useAuth();
	const currentWizardStep = (wizardStep || _.get(user, 'business.details.wizardStep', 2));
	const [step, setStep] = useState(currentWizardStep);

	useEffect(() => {
		setStep(_.get(user, 'business.details.wizardStep', 2));
		window.scrollTo(0, 0)
	}, []);

	const isTrial = (user?.billing?.subscription.isTrial
		&& !user?.business?.subscription
		&& (moment(user.business?.onboardingComplete).add(10, 'days') < moment())) || false
	const { willReachLimit } = user?.billing || false
	const hasDebt = user?.billing?.subscription?.hasDebt

	return (
		<>
			{step < 6 &&
				<div className='max-w-3xl p-1 md:p-4 min-h-full mx-auto'>
					<div className='m-1 md:m-5 space-y-3'>
						<div
							className='mt-2 grid grid-cols-4 text-xs sm:text-base divide-x divide-x-reverse text-center divide-gray-300 border rounded bg-gray-50'>
							<div className={`p-2 px-0 ${step === 2 ? 'bg-gray-200 text-gray-800 font-medium' : 'text-gray-400'}`}>1. פרטי העסק</div>
							<div className={`p-2 px-0 ${step === 3 ? 'bg-gray-200 text-gray-800 font-medium' : 'text-gray-400'}`}>2. זמנים</div>
							<div className={`p-2 px-0 ${step === 4 ? 'bg-gray-200 text-gray-800 font-medium' : 'text-gray-400'}`}>3. שירותים</div>
							<div className={`p-2 px-0 ${step === 5 ? 'bg-gray-200 text-gray-800 font-medium' : 'text-gray-400'}`}>4. סיום</div>
						</div>

						{step === 2 && <BusinessDetails setStep={setStep} />}
						{step === 3 && <BusinessSettings setStep={setStep} />}
						{step === 4 && <BusinessServices setStep={setStep} />}
						{step === 5 && <WizardComplete setStep={setStep} />}
					</div>
				</div>
			}
			<div className="max-w-5xl p-3 md:p-4 mx-auto overflow-hidden">

				{/* {user && user.billing && user.billing.willReachLimit &&
					<div className="flex justify-between items-center bg-red-50 border-r-4 border-red-500 text-red-600 p-3 py-2 -mt-2 mb-3 md:-mt-3 md:mb-5 rounded"
						role="alert">
						<div className='tracking-tight md:text-base w-full'>
							<strong className="animate-pulse font-bold pl-1 flex items-center">
								<FcHighPriority size={18} />
								<span className='pr-1'>שימו לב</span>
							</strong>
							<span className="block sm:inline text-sm md:text-base tracking-tight">אתם בדרך לניצול מקסימלי של התוכנית החודשית שלכם.</span>
						</div>
						<div className='flex w-36 justify-end'>
							<Link href={'/app/account/'}><a className="hover:opacity-90 shadow border border-red-500 text-white text-sm md:text-base font-medium p-1 px-1.5 py-1.5 rounded bg-red-500 ">החשבון שלי</a></Link>
						</div>
					</div>
				} */}
				{isTrial && <AccountBanner isTrial={isTrial} />}
				{willReachLimit && <AccountBanner willReachLimit={willReachLimit} />}
				{hasDebt && <AccountBanner hasDebt={hasDebt} />}
				{step === 6 && children}
			</div>
		</>
	);
}

export default BusinessWizard;


