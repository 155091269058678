import moment from 'moment'

function getCancellationText(appointment, isCustomer) {
    let text = isCustomer ? '' : 'התור בוטל'
    if (appointment.isAutoCancelled) text = 'התור התבטל עקב אי אישור הגעה'
    else if (appointment.hasOwnProperty('isManualCancelled')) {
        if (appointment.isManualCancelled) text = 'התור בוטל על ידי העסק'
        else text = isCustomer ? 'ביטלת את התור' : 'התור בוטל על ידי הלקוח'
    }
    return text
}

function getTimeSettings(user) {
    const timeSettings = _.get(user, 'business.timeSettings', {})
    if (!('breakBetweenAppointments' in timeSettings.limits)) timeSettings.limits.breakBetweenAppointments = 0
    return timeSettings
}

function getOptionsForBreak(options) {
    const optionsForBreak = options.slice(0, 3).concat(options[5])
    optionsForBreak.unshift({ value: 0, text: 'אל תקבע הפסקה' })
    return optionsForBreak
}

function getLinkToGoogleCalendar(appointment) {
    const { formattedDate, serviceLength, serviceName, pageId, location, businessName } = appointment
    const startTime = moment(formattedDate)
    const endTime = startTime.clone().add(serviceLength, 'minutes')
    const title = `${serviceName}`
    const description = `תור אצל ${businessName}.\n:לפרטי התור https://zmantov.co.il/c/${pageId}`
    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startTime.format('YYYYMMDDTHHmmss')}/${endTime.format('YYYYMMDDTHHmmss')}&details=${encodeURIComponent(description)}&location=${encodeURIComponent(location)}&text=${encodeURIComponent(title)}`
    return googleCalendarUrl
}

function getDisabledDays(availableTimes) {
    const disabledDays = availableTimes.reduce((acc, dayDetails) => {
        const { day, month, year, times } = dayDetails
        if (!times.length) acc.push(moment({ day, month: month - 1, year }).format('YYYY-MM-DD'))
        return acc
    }, [])

    return disabledDays
}

function getAvailableTimesInDay(selectedDay, availableTimes) {
    const selectedDayData = availableTimes.find(day => {
        return day.day === selectedDay.date() && day.month === selectedDay.month() + 1 && day.year === selectedDay.year()
    })

    return selectedDayData?.times || []
}

function getNextAvailableDay(selectedDay, availableTimes) {
    const nextDayAvailable = availableTimes.find(day => day.day > selectedDay.date() && day.times.length > 0)
    return nextDayAvailable || null
}

module.exports = {
    getCancellationText,
    getTimeSettings,
    getOptionsForBreak,
    getLinkToGoogleCalendar,
    getDisabledDays,
    getAvailableTimesInDay,
    getNextAvailableDay
}