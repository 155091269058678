import React, { useState } from 'react';

function TimeSelect({ option, defaultValue, onTimeSelect, startTime }) {

	const [selectedTime, setSelectedTime] = useState(defaultValue);

	const options = {
		hours: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
		minutes: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
	}

	const operatingHours = [];
	options.hours.map((hour) => {
		options.minutes.map((minute) => {
			operatingHours.push(`${hour}:${minute}`)
		})
	})

	const changeHandler = (event) => {
		const selectedTime = event.target.value;
		setSelectedTime(selectedTime);
		startTime ? option.startTime = selectedTime : option.endTime = selectedTime;
		onTimeSelect(option);
	}

	return (
		<>
			<div className="relative w-full md:w-20 ml-2 md:ml-0 cursor-pointer">
				<select onChange={changeHandler} className='bg-white block w-full cursor-pointer appearance-none border border-gray-200 text-gray-700 p-1 md:pr-2 rounded leading-tight focus:outline-none sm:hover:border-gray-500 focus:border-gray-500'
					value={selectedTime}>
					{
						operatingHours.map((time) => (
							<option className='text-right' key={time} value={time}>{time}</option>
						))
					}
				</select>
				<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
					<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
					</svg>
				</div>
			</div>
		</>
	)
		;
}

export default TimeSelect;


