import React from 'react';
import { BiCalendarPlus, BiUserPlus, BiCalendarX, BiGroup, BiUserMinus } from "react-icons/bi";
import moment from 'moment-timezone';
import Emoji from "a11y-react-emoji";
import TimeAgo from "react-timeago";
import heStrings from 'react-timeago/lib/language-strings/he'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { notificationTypes } from '../../../constants'
const { NEW_APPOINTMENT, NEW_MANUAL_APPOINTMENT, NEW_CUSTOMER, CANCELLED, CUSTOMER_REGISTERED_FOR_GROUP, CUSTOMER_CANCELLED_GROUP_REGISTRATION } = notificationTypes

const formatter = buildFormatter(heStrings)

function FeedItem({ event }) {
	return (
		<>
			{(event.type === NEW_APPOINTMENT || event.type === NEW_MANUAL_APPOINTMENT) && <NewAppointmentFeedItem event={event} />}
			{event.type === NEW_CUSTOMER && <NewCustomerFeedItem event={event} />}
			{event.type === CANCELLED && <CancelledFeedItem event={event} />}
			{event.type === CUSTOMER_REGISTERED_FOR_GROUP && <GroupRegistrationFeedItem event={event} />}
			{event.type === CUSTOMER_CANCELLED_GROUP_REGISTRATION && <CancelledGroupRegistrationFeedItem event={event} />}
		</>
	);
}

const NewCustomerFeedItem = ({ event }) => {
	return (
		<div className="relative overflow-hidden">
			<div className="flex items-center w-full ">
				<div className="order-1 bg-white w-auto text-xs space-y-1 py-3">
					<div className
						='space-x-1 space-x-reverse'>
						<Emoji symbol="🎉" label="confetti" />
						<span className='bg-yellow-100 py-px p-1 font-medium rounded text-yellow-600'>לקוח חדש</span>
					</div>
					<div className="text-xs text-gray-900 text-opacity-100 space-x-1 space-x-reverse">
						<span>{event.data.firstName + " " + event.data.lastName}</span>
						<span>{event.data.gender === 'male' ? 'הצטרף כלקוח לעסק שלך' : event.data.gender === 'female' ? 'הצטרפה כלקוחה לעסק שלך' : 'הצטרף/ה כלקוח/ה לעסק שלך'}</span>
					</div>
					<div style={{ fontSize: '0.65rem' }} className='font-medium text-blue-900 leading-none pt-1'>
						<TimeAgo date={event.createdAt} formatter={formatter} minPeriod={15} />
					</div>
				</div>
				<div className="z-20 text-center flex justify-center items-center flex-col px-4">
					<div
						className="text-gray-600 rounded-full flex items-center justify-center">
						<BiUserPlus size={30} />
					</div>
				</div>
			</div>
		</div>
	)
}

const NewAppointmentFeedItem = ({ event }) => {
	return (
		<div className="relative overflow-hidden">
			<div className="flex items-center w-full">
				<div className="order-1 bg-white w-auto text-xs space-y-1 py-3">
					<div className="text-xs text-gray-900 text-opacity-100 space-x-1 space-x-reverse">
						{
							event.data.isManual ?
								<>
									<>
										<span>קבעתם תור עבור {_.get(event.customerDetails[0], 'firstName', 'לא קיים') + " " + _.get(event.customerDetails[0], 'lastName', 'לא קיים')}</span>
									</>
								</>
								:
								<>
									<span>{_.get(event.customerDetails[0], 'firstName', 'לא קיים') + " " + _.get(event.customerDetails[0], 'lastName', 'לא קיים')}</span>
									<span>{_.get(event.customerDetails[0], 'gender', 'לא קיים') === 'male' ? 'קבע' : _.get(event.customerDetails[0], 'gender', 'לא קיים') === 'female' ? 'קבעה' : 'קבע/ה'} תור</span>
								</>
						}
						<span>ל{_.get(event.serviceDetails[0], 'name', '(שירות נמחק)')}</span>
					</div>
					<div>
						<span
							className='text-gray-500'>יום {moment(event.data.date).format('dddd, L בשעה HH:mm')}</span>
					</div>
					<div style={{ fontSize: '0.65rem' }} className='font-medium text-blue-900 leading-none pt-1'>
						<TimeAgo date={event.createdAt} formatter={formatter} minPeriod={15} />
					</div>
				</div>
				<div className="z-20 text-center flex justify-center items-center flex-col px-4">
					<div className="text-green-500 rounded-full flex items-center justify-center">
						<BiCalendarPlus size={30} />
					</div>
				</div>
			</div>
		</div>
	)
}

const CancelledFeedItem = ({ event }) => {
	return (
		<div className="relative overflow-hidden">
			<div className="flex items-center w-full">
				<div className="order-1 bg-white w-auto text-xs space-y-1 py-3">
					<div className="text-xs text-gray-900 text-opacity-100 space-x-1 space-x-reverse">
						<span>
							בוטל התור של {_.get(event.customerDetails[0], 'firstName', 'לא קיים') + " " + _.get(event.customerDetails[0], 'lastName', 'לא קיים')}
						</span>
						<span>ל{_.get(event.serviceDetails[0], 'name', '(שירות נמחק)')}</span>
					</div>
					<div>
						<span className='text-gray-500'>יום {moment(event.data.date).format('dddd, L בשעה HH:mm')}</span>
					</div>
					<div style={{ fontSize: '0.65rem' }} className='font-medium text-blue-900 leading-none pt-1'>
						<TimeAgo date={event.createdAt} formatter={formatter} minPeriod={15} />
					</div>
				</div>
				<div className="z-20 text-center flex justify-center items-center flex-col px-4">
					<div className="text-red-500 rounded-full flex items-center justify-center">
						<BiCalendarX size={30} />
					</div>
				</div>
			</div>
		</div>
	)
}

function GroupRegistrationFeedItem({ event }) {
	return (
		<div className="relative overflow-hidden">
			<div className="flex items-center w-full">
				<div className="order-1 bg-white w-auto text-xs space-y-1 py-3">
					<div className="text-xs text-gray-900 text-opacity-100 space-x-1 space-x-reverse">
						<span>
							{_.get(event.customerDetails[0], 'firstName', 'לא קיים') + " " + _.get(event.customerDetails[0], 'lastName', 'לא קיים')}
						</span>
						<span>{_.get(event.customerDetails[0], 'gender', 'לא קיים') === 'male' ? 'נרשם' : _.get(event.customerDetails[0], 'gender', 'לא קיים') === 'female' ? 'נרשמה' : 'נרשם/ה'} ל{_.get(event.group[0], 'groupName', 'שיעור ללא שם')}</span>
					</div>
					<div>
						<span className='text-gray-500'>
							יום {moment(event.group[0].date).format('dddd, L')} בשעה {event.group[0].startTime}
						</span>
					</div>
					<div style={{ fontSize: '0.65rem' }} className='font-medium  text-blue-900 leading-none pt-1'>
						<TimeAgo date={event.createdAt} formatter={formatter} minPeriod={15} />
					</div>
				</div>
				<div className="z-20 text-center flex justify-center items-center flex-col px-4">
					<div className="text-green-500 rounded-full flex items-center justify-center">
						<BiGroup size={30} />
					</div>
				</div>
			</div>
		</div>
	)
}

function CancelledGroupRegistrationFeedItem({ event }) {
	return (
		<div className="relative overflow-hidden">
			<div className="flex items-center w-full">
				<div className="order-1 bg-white w-auto text-xs space-y-1 py-3">
					<div className="text-xs text-gray-900 text-opacity-100 space-x-1 space-x-reverse">
						<span>{_.get(event.customerDetails[0], 'firstName', 'לא קיים') + " " + _.get(event.customerDetails[0], 'lastName', 'לא קיים')}</span>
						<span>{_.get(event.customerDetails[0], 'gender', 'לא קיים') === 'male' ? 'ביטל' : _.get(event.customerDetails[0], 'gender', 'לא קיים') === 'female' ? 'ביטלה' : 'ביטל/ה'} את ההרשמה ל{_.get(event.group[0], 'groupName', 'שיעור ללא שם')}</span>
					</div>
					<div>
						<span className='text-gray-500'>
							יום {moment(event.group[0].date).format('dddd, L')} בשעה {event.group[0].startTime}
						</span>
					</div>
					<div style={{ fontSize: '0.65rem' }} className='font-medium text-blue-900 leading-none pt-1'>
						<TimeAgo date={event.createdAt} formatter={formatter} minPeriod={15} />
					</div>
				</div>
				<div className="z-20 text-center flex justify-center items-center flex-col px-4">
					<div className="text-red-500 rounded-full flex items-center justify-center">
						<BiUserMinus size={30} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default FeedItem;
