import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import NumberStepper from "../../../common/NumberStepper";
import utils from "../../../../utils";
import { useAPI } from "../../../../contexts/api";
import { CgSpinner } from "react-icons/cg";
import LoaderIcon from "../../../common/LoaderIcon";
import { toast } from "react-toastify";
import useUser from '../../../../lib/useUser'
import { advanced, advancedOnSale, unlimited } from '../../../../plans'
import { FaLock } from "react-icons/fa"
import Link from "next/link"

function AddServiceModal({ callback, showModal, setShowModal, serviceToEdit }) {
	const { addNewService, updateService, loading, archiveService, getServiceProviders } = useAPI();
	const defaultValue = ((serviceToEdit && serviceToEdit.name) ? serviceToEdit : {
		name: '',
		description: '',
		length: 30,
		price: 30,
		startingPrice: false
	});
	const [service, setService] = useState(defaultValue)
	const [showConfirmArchive, setShowConfirmArchive] = useState(false);
	const [archivingService, setArchivingService] = useState(false);
	const [serviceProviders, setServiceProviders] = useState([])
	const [isSelectOpen, setIsSelectOpen] = useState(false)
	const { user } = useUser()
	const subscription = user?.billing?.subscription

	useEffect(() => {
		setShowConfirmArchive(false);
		setService(((serviceToEdit && serviceToEdit.name) ? serviceToEdit : {
			name: '',
			description: '',
			length: 30,
			price: 30,
			startingPrice: false,
		}))
		if (isAllowedToChooseServiceProviders()) loadServiceProviders()
	}, [serviceToEdit, showModal])

	async function loadServiceProviders() {
		let serviceProviders = await getServiceProviders()
		if (serviceToEdit._id && serviceToEdit.serviceProviderIds.length) {
			const filteredServiceProviders = serviceProviders.map(serviceProvider => {
				let isSelected = false
				if (serviceToEdit.serviceProviderIds?.includes(serviceProvider._id)) isSelected = true
				return { ...serviceProvider, isSelected }
			})
			if (filteredServiceProviders.some(serviceProvider => serviceProvider.isSelected)) serviceProviders = filteredServiceProviders
			else serviceProviders = getDefaultServiceProviders(serviceProviders)
		} else serviceProviders = getDefaultServiceProviders(serviceProviders)
		setServiceProviders([...serviceProviders])
	}

	const handleChange = async (e) => {
		setService({ ...service, startingPrice: (e.target.value == 'true') })
	}

	const handleSaveService = async (e) => {
		e.preventDefault();
		let res;
		if (isAllowedToChooseServiceProviders()) {
			const selectedServiceProviders = serviceProviders.filter(serviceProvider => serviceProvider.isSelected)
			if (!selectedServiceProviders.length) {
				toast.error('יש לבחור לפחות איש צוות אחד')
				return
			}
		}
		if (service._id && service.businessId) {
			res = await updateService({ serviceId: service._id, service, serviceProviders });
		} else {
			res = await addNewService({ service, serviceProviders });
		}
		if (res && res.data.success) {
			callback(true);
		}
	}

	const handleArchive = async () => {
		setArchivingService(true);
		const res = await archiveService(service._id);
		if (res) {
			const { data } = res;
			if (data && data.success) {
				setArchivingService(false);
				setShowModal(false);
				callback(true);
			}
		}
	}

	function toggleServiceProvider(ev, serviceProviderId) {
		ev.stopPropagation()
		setServiceProviders(prevServiceProviders => {
			return prevServiceProviders.map(serviceProvider => {
				if (serviceProvider._id === serviceProviderId) return { ...serviceProvider, isSelected: !serviceProvider.isSelected }
				return serviceProvider
			})
		})

	}

	function getSelectedServiceProvidersNum() {
		return serviceProviders.filter(serviceProvider => serviceProvider.isSelected).length
	}

	function toggleSelect(ev) {
		ev.stopPropagation()
		setIsSelectOpen(prevIsSelectOpen => !prevIsSelectOpen)
	}

	function isAllowedToChooseServiceProviders() {
		return subscription?.plan === advanced.plan ||
			subscription?.plan === advancedOnSale.plan ||
			subscription?.plan === unlimited.plan ||
			subscription?.isTrial
	}

	function getDefaultServiceProviders(serviceProviders) {
		return serviceProviders.map(serviceProvider => ({ ...serviceProvider, isSelected: true }))
	}

	return (
		<>
			{showModal ? (
				<>
					<div className="opacity-25 fixed inset-0 z-40 bg-black overflow-auto w-full h-full" onClick={() => { setShowModal(false); setShowConfirmArchive(false); }} />
					<div className="overflow-x-hidden overflow-y-auto h-full fixed w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 outline-none focus:outline-none "
						style={{ zIndex: 100 }}>
						<div className="my-6 sm:my-16 px-2 sm:mx-auto w-full md:max-w-lg"
							onClick={() => setIsSelectOpen(false)}>
							{/*content*/}
							<div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div
									className="flex item-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="text-xl md:text-2xl font-semibold">
										{service._id ? 'עריכת שירות' : 'הוספת שירות'}
									</h3>
									<button
										className="p-1 border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowModal(false)}>
										<GrClose size={21} />
									</button>
								</div>
								{/*body*/}
								<div className="relative p-4 flex-auto">
									{
										!isAllowedToChooseServiceProviders() &&
										<div className='flex text-sm text-gray-600 mb-4 bg-green-100 p-3 rounded'>
											<span className='ml-2'><FaLock /></span>
											<div>
												<div className='ml-1'>על מנת לשייך את השירות לאיש צוות מסוים יש לשדרג את החבילה</div>
												<span className='underline hover:text-gray-800 transition-all'>
													<Link href="/app/account/upgrade/" className="">
														לחצו כאן
													</Link>
												</span>
											</div>
										</div>
									}
									<form className="bg-white space-y-4" onSubmit={handleSaveService}>
										<div className="form-control">
											<label className="label ">
												<span className="label-text md:text-base text-gray-800">שם השירות</span>
											</label>
											<input type="text" value={service.name} onChange={(e) => {
												setService({ ...service, name: e.target.value })
											}} name='name' required
												placeholder="לדוגמא: תספורת + זקן"
												className="border px-3 h-12 bg-white text-sm" />
										</div>
										<div className="form-control">
											<label className="label ">
												<span
													className="label-text md:text-base text-gray-800">תיאור השירות</span>
											</label>
											<input type="text" value={service.description} onChange={(e) => {
												setService({ ...service, description: e.target.value })
											}} name='name'
												placeholder="לדוגמא: חפיפה, תספורת מקצועית ועיצוב הזקן."
												className="border px-3 h-12 bg-white text-sm" />
										</div>
										{
											isAllowedToChooseServiceProviders() &&
											<div className="form-control">
												<label className="label">
													<span className="label-text md:text-base text-gray-800">בחירת איש צוות</span>
												</label>
												<div
													className='select select-bordered bg-white border border-gray-200 font-normal items-center relative'
													onClick={toggleSelect}>
													<div>{serviceProviders?.length ? `${getSelectedServiceProvidersNum()} אנשי צוות נבחרו` : 'בחירת איש צוות'}</div>
													{isSelectOpen && <ul onClick={ev => ev.stopPropagation()}
														className='absolute right-0 select-bordered bg-white w-full max-h-52 overflow-y-auto'
														style={{ boxShadow: '0px 0px 1px 0px black', top: '100%' }}
														onTouchStart={(ev) => ev.stopPropagation()}>
														{serviceProviders.map(serviceProvider => {
															return <li key={serviceProvider._id} className='hover:bg-gray-100 flex py-1'>
																<label className='cursor-pointer mr-3 flex items-center' style={{ flexGrow: '1' }}>
																	<input type="checkbox" checked={serviceProvider.isSelected}
																		onChange={ev => toggleServiceProvider(ev, serviceProvider._id)}
																		className='ml-2' />
																	{`${serviceProvider.firstName} ${serviceProvider.lastName}`}
																</label>
															</li>
														})}
													</ul>}
												</div>
											</div>
										}
										<div className="flex flex-row justify-between items-center">
											<div className="form-control w-full ml-1">
												<label className="label ">
													<span className="label-text md:text-base text-gray-800">משך השירות <span
														className='text-gray-400 text-xs'>(בדקות)</span></span>
												</label>
												<NumberStepper value={service.length} steps={5}
													changeHandler={(updated) => {
														setService({ ...service, length: updated })
													}} />
												<div className="text-gray-400 text-sm mt-2 flex justify-center">
													<div className='ml-1'>{utils.timeConvert(service.length)}</div>
												</div>
											</div>
											<div className="form-control w-full mr-1">
												<label className="label ">
													<span className="label-text md:text-base text-gray-800">עלות השירות</span>
												</label>
												<NumberStepper value={service.price} changeHandler={(updated) => {
													setService({ ...service, price: updated })
												}} />
												<div className="text-gray-400 text-sm mt-2 flex justify-center">
													<div className='ml-1'>
														{service.startingPrice ? `החל מ-${service.price}` : service.price}
													</div>
													<div>₪</div>
												</div>
											</div>
										</div>

										<div className="form-control">
											<label className="label ">
												<span className="label-text md:text-base text-gray-800">מחיר השירות הינו</span>
											</label>
											<select value={service.startingPrice}
												className='select select-bordered bg-white border border-gray-200 font-normal'
												onChange={handleChange} required>
												<option value='false'>מחיר קבוע - ₪{service.price}</option>
												<option value='true'>החל מ-₪{service.price}</option>
											</select>

											{(serviceToEdit && serviceToEdit.name) &&
												<>
													{showConfirmArchive ? <button type='button' onClick={handleArchive}
														disabled={archivingService}
														className='hover:opacity-90 flex items-center justify-center disabled:opacity-75 bg-red-500 w-full p-2 rounded text-white mt-3 h-10'>
														<LoaderIcon size={24} spin={archivingService}>לחצו שוב לאישור מחיקה</LoaderIcon>
													</button>
														:
														<button type='button'
															onClick={() => setShowConfirmArchive(true)}
															disabled={archivingService}
															className='hover:opacity-90 flex items-center justify-center disabled:opacity-75 bg-white border-red-500 border w-full p-2 rounded text-red-500 mt-3 h-10'>
															מחיקת שירות
														</button>
													}
													<p className='text-center tracking-tight text-sm pt-2 text-red-500 px-3'>מחיקת השירות אינה ניתנת לשחזור. נתוני הסטטיסטיקה יישמרו במערכת.</p>
												</>
											}

										</div>

										<div className="flex items-center border-t border-solid pt-4 rounded-b">
											<button
												className="flex justify-center items-center w-24 disabled:bg-blue-100 h-12 hover:bg-blue-400 bg-blue-500 border-blue-500 border text-white font-bold uppercase text-sm px-8 py-3 rounded outline-none"
												type="submit">
												{loading ? <CgSpinner className='mx-auto animate-spin' size={30} /> :
													<span>שמירה</span>}
											</button>
											<button
												className="text-gray-700 border border-gray-400 hover:opacity-75 h-12 background-transparent font-bold uppercase px-6 py-3 text-sm outline-none rounded focus:outline-none mr-1"
												type="button"
												onClick={() => {
													setShowModal(false);
													setService(defaultValue);
													setShowConfirmArchive(false);
												}}>
												ביטול
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	)
}

export default AddServiceModal;

