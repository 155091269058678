import React, { useState } from 'react';
import { BiUpload } from 'react-icons/bi'
import { useAuth } from "../../../../contexts/auth";
import axios from 'axios';
import Image from 'next/image'
import { CgSpinner } from "react-icons/cg";

function LogoImageUpload() {

	const [selectedImage, setSelectedImage] = useState('');
	const [uploading, setUploading] = useState(false);
	const { user } = useAuth();

	const handleProfileImageUpload = async (e) => {
		const file = e.target.files[0];
		if (file) {
			setUploading(true);
			const filePreview = URL.createObjectURL(file);
			setSelectedImage(filePreview)
			const formData = new FormData();
			formData.append('file', file);
			const { data } = await axios.post(`/api/business/updateLogo/`, formData, {
				headers: {
					'Content-Type': 'image/jpg'
				}
			})
			setUploading(false);
		}

	}


	return (
		<>
			<div className="bg-white px-4 py-5 text-center mx-auto w-56">
				<div className="mb-4 ">
					{uploading ?
						<div
							className='w-50 h-48 mx-auto p-2 flex justify-center items-center'>
							<CgSpinner className='mx-auto animate-spin text-gray-400' size={70} />
						</div>
						:
						<img
							className="w-56 h-48 mx-auto rounded-full object-cover object-center p-1 shadow border border-gray-200"
							src={selectedImage ? selectedImage : user.business && user.business.details && user.business.details.logo ? `https://zmantov.fra1.digitaloceanspaces.com/logos/${user.business.details.logo}` : "/img/business/store-placeholder.png"}
							alt="Avatar Upload" />

					}
				</div>
				<label className="cursor-pointer mt-6">
					<span
						className="mt-2 text-base leading-normal px-4 shadow flex justify-center font-semibold items-center py-3 bg-blue-500 hover:bg-blue-600 text-white text-sm rounded-md">
						<div className='ml-2'>העלאת לוגו</div>
						<BiUpload />
					</span>
					<input accept="image/*" disabled={uploading} onChange={handleProfileImageUpload} type='file'
						className="hidden" />
				</label>
			</div>
		</>
	);
}

export default LogoImageUpload;

